import { z } from "zod";
import { userSchema } from "../../jason-proof-of-concept/users/domain/user";
import { companySchema } from "../../companies/domain/company";

export const paymentSchema = z.object({
    id: z.string().uuid(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date(),
    createdById: z.string().uuid().nullish(),
    companyId: z.number(),
    company: companySchema.nullish(),
    date: z.coerce.date(),
    amount: z.number(),
    to: z.string().nullish(),
    toUserId: z.string().uuid().nullish(),
    toUser: userSchema.nullish(),
    toClearErc: z.boolean().nullish(),
    toCompanyId: z.number().nullish(),
    toCompany: companySchema.nullish(),
    from: z.string().nullish(),
    fromUserId: z.string().uuid().nullish(),
    fromUser: userSchema.nullish(),
    fromClearErc: z.boolean().nullish(),
    fromCompanyId: z.number().nullish(),
    fromCompany: companySchema.nullish(),
    reference: z.string().nullish(),
    notes: z.string().nullish(),
    description: z.string().nullish(),
    paymentRequestId: z.string().uuid().nullish(),
    lineItemId: z.number().nullish(),
    type: z.enum(["fee"]).nullish(),
});

export type Payment = z.infer<typeof paymentSchema>;
