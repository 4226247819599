import { useNavigate } from "react-router";
import { formatCurrency } from "../../billing/utils";
import {
    RepairDeal,
    affiliateCommissionPercentage,
    commissionOnSubAffiliatePercentage,
} from "../affiliate-repair-deals-page";

export const RepairDealsCard = ({ repairDeal }: { repairDeal: RepairDeal }) => {
    const navigate = useNavigate();
    const isMainAffiliate = repairDeal.affiliateType === "affiliate";
    const commissionPercentage = isMainAffiliate ? affiliateCommissionPercentage : commissionOnSubAffiliatePercentage;
    return (
        <div
            className={`shadow-md rounded-lg p-3  cursor-pointer mb-4 ${
                isMainAffiliate ? "bg-indigo-50 dark:bg-indigo-900" : "bg-green-50 dark:bg-green-900"
            }`}
            onClick={() => navigate(`/companies/${repairDeal.company?.id}`)}
        >
            <div className="mb-2">
                <p className="text-xs font-bold">{repairDeal.company?.name}</p>
                <div
                    className={`${
                        isMainAffiliate ? "bg-indigo-200 dark:bg-indigo-800" : "bg-green-200 dark:bg-green-800"
                    } rounded-md text-xxxs px-2 py-1 mt-1 w-fit`}
                >
                    {repairDeal.repairStage?.title}
                </div>
            </div>
            <p className="text-xs ">
                Total commissionable products:{" "}
                <span className="font-bold">{formatCurrency(repairDeal.totalCommissionableLineItems || 0)}</span>
            </p>
            <div className="border-b-[1px] border-gray-400 my-2" />
            <p className={`text-xs ${isMainAffiliate ? "text-indigo-400" : "text-green-400"} font-bold`}>Commission</p>
            <p className="text-xs ">
                Total:{" "}
                <span className="font-bold">
                    {formatCurrency((repairDeal?.totalCommissionableLineItems || 0) * commissionPercentage)} (
                    {isMainAffiliate ? "15" : "5"}%)
                </span>
            </p>
            <p className="text-xs ">
                Due: <span className="font-bold">{formatCurrency(repairDeal.totalCommissionDue)}</span>
            </p>
            <p className="text-xs ">
                Paid: <span className="font-bold">{formatCurrency(repairDeal.totalCommissionPaid)}</span>
            </p>
            <div className="border-b-[1px] border-gray-400 my-2" />
            <p className="text-xs ">
                {isMainAffiliate ? "Affiliate" : "Sub-Affiliate"}:{" "}
                <span className="font-bold">
                    {repairDeal.affiliate?.firstName} {repairDeal.affiliate?.lastName}
                </span>
            </p>
        </div>
    );
};
